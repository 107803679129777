html,
body,
.App,
#root {
    height: 100%;
    background-color: #000000;
    overflow: hidden;
}
html {
    background-color: #000000;
}
body {
    background-color: #000000;
}
.App {
    text-align: center;
    background-color: #000000;
}

a {
    text-decoration: none;
    color: inherit;
}

.MuiDialog-root {
    z-index: 2000 !important;
}

.MuiAutocomplete-popper {
    z-index: 3000 !important;
}
